exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-changelog-jsx": () => import("./../../../src/pages/changelog.jsx" /* webpackChunkName: "component---src-pages-changelog-jsx" */),
  "component---src-pages-coming-soon-jsx": () => import("./../../../src/pages/coming-soon.jsx" /* webpackChunkName: "component---src-pages-coming-soon-jsx" */),
  "component---src-pages-components-accordions-index-jsx": () => import("./../../../src/pages/components/accordions/index.jsx" /* webpackChunkName: "component---src-pages-components-accordions-index-jsx" */),
  "component---src-pages-components-alerts-index-jsx": () => import("./../../../src/pages/components/alerts/index.jsx" /* webpackChunkName: "component---src-pages-components-alerts-index-jsx" */),
  "component---src-pages-components-app-buttons-index-jsx": () => import("./../../../src/pages/components/app-buttons/index.jsx" /* webpackChunkName: "component---src-pages-components-app-buttons-index-jsx" */),
  "component---src-pages-components-avatars-index-jsx": () => import("./../../../src/pages/components/avatars/index.jsx" /* webpackChunkName: "component---src-pages-components-avatars-index-jsx" */),
  "component---src-pages-components-badges-index-jsx": () => import("./../../../src/pages/components/badges/index.jsx" /* webpackChunkName: "component---src-pages-components-badges-index-jsx" */),
  "component---src-pages-components-breadcrumbs-index-jsx": () => import("./../../../src/pages/components/breadcrumbs/index.jsx" /* webpackChunkName: "component---src-pages-components-breadcrumbs-index-jsx" */),
  "component---src-pages-components-button-groups-index-jsx": () => import("./../../../src/pages/components/button-groups/index.jsx" /* webpackChunkName: "component---src-pages-components-button-groups-index-jsx" */),
  "component---src-pages-components-button-switchers-index-jsx": () => import("./../../../src/pages/components/button-switchers/index.jsx" /* webpackChunkName: "component---src-pages-components-button-switchers-index-jsx" */),
  "component---src-pages-components-buttons-index-jsx": () => import("./../../../src/pages/components/buttons/index.jsx" /* webpackChunkName: "component---src-pages-components-buttons-index-jsx" */),
  "component---src-pages-components-cards-index-jsx": () => import("./../../../src/pages/components/cards/index.jsx" /* webpackChunkName: "component---src-pages-components-cards-index-jsx" */),
  "component---src-pages-components-carousels-index-jsx": () => import("./../../../src/pages/components/carousels/index.jsx" /* webpackChunkName: "component---src-pages-components-carousels-index-jsx" */),
  "component---src-pages-components-chip-notifications-index-jsx": () => import("./../../../src/pages/components/chip-notifications/index.jsx" /* webpackChunkName: "component---src-pages-components-chip-notifications-index-jsx" */),
  "component---src-pages-components-dropdowns-index-jsx": () => import("./../../../src/pages/components/dropdowns/index.jsx" /* webpackChunkName: "component---src-pages-components-dropdowns-index-jsx" */),
  "component---src-pages-components-fabs-index-jsx": () => import("./../../../src/pages/components/fabs/index.jsx" /* webpackChunkName: "component---src-pages-components-fabs-index-jsx" */),
  "component---src-pages-components-features-index-jsx": () => import("./../../../src/pages/components/features/index.jsx" /* webpackChunkName: "component---src-pages-components-features-index-jsx" */),
  "component---src-pages-components-feeds-index-jsx": () => import("./../../../src/pages/components/feeds/index.jsx" /* webpackChunkName: "component---src-pages-components-feeds-index-jsx" */),
  "component---src-pages-components-footers-index-jsx": () => import("./../../../src/pages/components/footers/index.jsx" /* webpackChunkName: "component---src-pages-components-footers-index-jsx" */),
  "component---src-pages-components-form-elements-checkboxes-index-jsx": () => import("./../../../src/pages/components/form-elements/checkboxes/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-checkboxes-index-jsx" */),
  "component---src-pages-components-form-elements-datepickers-index-jsx": () => import("./../../../src/pages/components/form-elements/datepickers/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-datepickers-index-jsx" */),
  "component---src-pages-components-form-elements-file-inputs-index-jsx": () => import("./../../../src/pages/components/form-elements/file-inputs/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-file-inputs-index-jsx" */),
  "component---src-pages-components-form-elements-input-groups-index-jsx": () => import("./../../../src/pages/components/form-elements/input-groups/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-input-groups-index-jsx" */),
  "component---src-pages-components-form-elements-radio-groups-index-jsx": () => import("./../../../src/pages/components/form-elements/radio-groups/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-radio-groups-index-jsx" */),
  "component---src-pages-components-form-elements-search-inputs-index-jsx": () => import("./../../../src/pages/components/form-elements/search-inputs/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-search-inputs-index-jsx" */),
  "component---src-pages-components-form-elements-select-menus-index-jsx": () => import("./../../../src/pages/components/form-elements/select-menus/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-select-menus-index-jsx" */),
  "component---src-pages-components-form-elements-textareas-index-jsx": () => import("./../../../src/pages/components/form-elements/textareas/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-textareas-index-jsx" */),
  "component---src-pages-components-form-elements-toggles-index-jsx": () => import("./../../../src/pages/components/form-elements/toggles/index.jsx" /* webpackChunkName: "component---src-pages-components-form-elements-toggles-index-jsx" */),
  "component---src-pages-components-jsx": () => import("./../../../src/pages/components.jsx" /* webpackChunkName: "component---src-pages-components-jsx" */),
  "component---src-pages-components-layouts-index-jsx": () => import("./../../../src/pages/components/layouts/index.jsx" /* webpackChunkName: "component---src-pages-components-layouts-index-jsx" */),
  "component---src-pages-components-lists-index-jsx": () => import("./../../../src/pages/components/lists/index.jsx" /* webpackChunkName: "component---src-pages-components-lists-index-jsx" */),
  "component---src-pages-components-modals-index-jsx": () => import("./../../../src/pages/components/modals/index.jsx" /* webpackChunkName: "component---src-pages-components-modals-index-jsx" */),
  "component---src-pages-components-navbars-index-jsx": () => import("./../../../src/pages/components/navbars/index.jsx" /* webpackChunkName: "component---src-pages-components-navbars-index-jsx" */),
  "component---src-pages-components-notifications-index-jsx": () => import("./../../../src/pages/components/notifications/index.jsx" /* webpackChunkName: "component---src-pages-components-notifications-index-jsx" */),
  "component---src-pages-components-pagination-index-jsx": () => import("./../../../src/pages/components/pagination/index.jsx" /* webpackChunkName: "component---src-pages-components-pagination-index-jsx" */),
  "component---src-pages-components-pricing-index-jsx": () => import("./../../../src/pages/components/pricing/index.jsx" /* webpackChunkName: "component---src-pages-components-pricing-index-jsx" */),
  "component---src-pages-components-progress-bars-index-jsx": () => import("./../../../src/pages/components/progress-bars/index.jsx" /* webpackChunkName: "component---src-pages-components-progress-bars-index-jsx" */),
  "component---src-pages-components-ratings-index-jsx": () => import("./../../../src/pages/components/ratings/index.jsx" /* webpackChunkName: "component---src-pages-components-ratings-index-jsx" */),
  "component---src-pages-components-side-navigation-index-jsx": () => import("./../../../src/pages/components/side-navigation/index.jsx" /* webpackChunkName: "component---src-pages-components-side-navigation-index-jsx" */),
  "component---src-pages-components-sliders-index-jsx": () => import("./../../../src/pages/components/sliders/index.jsx" /* webpackChunkName: "component---src-pages-components-sliders-index-jsx" */),
  "component---src-pages-components-spinners-index-jsx": () => import("./../../../src/pages/components/spinners/index.jsx" /* webpackChunkName: "component---src-pages-components-spinners-index-jsx" */),
  "component---src-pages-components-tables-index-jsx": () => import("./../../../src/pages/components/tables/index.jsx" /* webpackChunkName: "component---src-pages-components-tables-index-jsx" */),
  "component---src-pages-components-tabs-index-jsx": () => import("./../../../src/pages/components/tabs/index.jsx" /* webpackChunkName: "component---src-pages-components-tabs-index-jsx" */),
  "component---src-pages-components-testimonials-index-jsx": () => import("./../../../src/pages/components/testimonials/index.jsx" /* webpackChunkName: "component---src-pages-components-testimonials-index-jsx" */),
  "component---src-pages-components-tooltips-index-jsx": () => import("./../../../src/pages/components/tooltips/index.jsx" /* webpackChunkName: "component---src-pages-components-tooltips-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-license-jsx": () => import("./../../../src/pages/legal/license.jsx" /* webpackChunkName: "component---src-pages-legal-license-jsx" */),
  "component---src-pages-legal-privacy-policy-jsx": () => import("./../../../src/pages/legal/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-jsx" */),
  "component---src-pages-legal-terms-jsx": () => import("./../../../src/pages/legal/terms.jsx" /* webpackChunkName: "component---src-pages-legal-terms-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */)
}

